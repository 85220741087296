<template>
    <div class="pagination">
        <el-pagination
                @size-change="sizeChange"
                @current-change="currentChange"
                :current-page="currentPage"
                :page-size="pageSize"
                :page-sizes="pageSizes"
                layout="->,total, sizes, prev, pager, next, jumper"
                :total="total"
                background
                small
        >
        </el-pagination>
        <el-row class="pagination-size">
            <p class="inputTextCss" style="margin-right: 3px">每页</p>
            <el-input class="inputCss" type="number" min="1" v-model="newPageSize" @blur="blur"/>
            <p class="inputTextCss" style="margin-left: 3px">条</p>
        </el-row>
    </div>
</template>

<script>
export default {
    name: "index",
    props: {
        // 当前页
        currentPage: {
            type: String | Number,
            default: 1
        },
        pageSize: {
            type: String | Number,
            default: 10
        },
        total: {
            type: String | Number,
            default: 0
        },
    },
    data() {
        return {
            newPageSize: 10,
            pageSizes: [10, 20, 30, 40, 50, 100]
        }
    },
    watch: {
        pageSize(newVal) {
            this.newPageSize = newVal
        }
    },
    methods: {
        sizeChange(val) {
            this.newPageSize = val
            this.$emit('size-change', val)
        },
        currentChange(val) {
            this.$emit('current-change', val)
        },
        blur() {
            const newPageSize = +this.newPageSize
            console.log(newPageSize, 'newPageSize')
            const maps = new Map()
            for (let i of this.pageSizes) {
                maps.set(i, i)
            }
            if (!maps.has(newPageSize)) {
                this.pageSizes.push(newPageSize)
            }
            this.pageSizes = this.pageSizes.sort((a, b) => a - b)
            if (this.pageSizes.length > 7) {
                this.pageSizes = this.pageSizes.filter(item => [newPageSize, 10, 20, 30, 40, 50, 100].includes(item))
            }
            console.log(this.pageSizes, 'this.pageSizes')
            this.$emit('size-change', newPageSize)
        }
    }
}
</script>

<style scoped>
.pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.pagination-size {
    margin-left: 10px;
    display: flex;
    height: 22px;
    align-items: center;
}
</style>