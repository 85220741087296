<template>
    <div class="box">
        <el-container>
            <el-header class="hander">
                <navmeun></navmeun>
            </el-header>
            <div class="navbarr" @contextmenu.prevent="contextmenu">
                <el-tag
                        style="margin-top: -2px;"
                        v-for="(item, index) in this.tagList"
                        :key="index"
                        :class="{currentPath:currentPath === item.path}"
                        closable
                        @close="handleClose(index,currentPath === item.path)"
                        @click="open(item)"
                        disable-transitions
                >
                    {{ item.title }}
                </el-tag>
            </div>
            <el-main class="content">
                <router-view></router-view>
            </el-main>
        </el-container>

        <vue-context-menu
                :contextMenuData="contextMenuData"
                @delRight="delRight"
                @delLeft="delLeft"
                @delOhter="delOhter"
                @closeAll="closeAll"
        ></vue-context-menu>

    </div>
</template>

<script>
import navmeun from "../../components/navmeun.vue";
import config from "@/config";

export default {
    components: {navmeun},
    data() {
        return {
            // tag标签
            tagList: [],
            list: JSON.parse(localStorage.getItem('tagList')),
            aa: [],
            currentPath: '', //当前路由地址
            contextMenuData: {
                // the contextmenu name(@1.4.1 updated)
                menuName: "demo",
                // The coordinates of the display(菜单显示的位置)
                axis: {
                    x: null,
                    y: null
                },
                // Menu options (菜单选项)
                menulists: [
                    // {
                    //   fnHandler: "delRight", // Binding events(绑定事件)
                    //   icoName: "fa fa-home fa-fw",
                    //   btnName: "关闭右侧" // The name of the menu option (菜单名称)
                    // },
                    {
                        fnHandler: "delLeft",
                        icoName: "fa fa-home fa-fw",
                        btnName: "关闭当前页"
                    },
                    {
                        fnHandler: "delOhter",
                        btnName: "关闭其他页"
                    },
                    {
                        fnHandler: "closeAll",
                        btnName: "关闭全部页"
                    }
                ]
            }

        };
    },
    mounted() {
        this.list = JSON.parse(localStorage.getItem('tagList'))
        this.aa = JSON.parse(localStorage.getItem('tagList'))
        // console.log(this.aa)
        this.tagList = this.aa
        this.query()

        document.addEventListener('visibilitychange', this.handleVisiable)
    },
    destroyed() {
        document.removeEventListener('visibilitychange', this.handleVisiable)
    },

    methods: {
        handleVisiable(e) {
            if (e.target.visibilityState == 'visible' && localStorage.getItem('loginUid')) {
                localStorage.removeItem('loginUid')
                this.$router.push('/home/page')
                this.$router.go(0)
                // alert('我跳转了页面')
            }

        },
        delRight() {
            //关闭右侧
            alert('关闭右侧')
        },
        delLeft() {
            //关闭当前页

            console.log(this.tagList)
            console.log(this.currentPath)
            const index = this.tagList.findIndex(item => item.path === this.currentPath);
            if (this.tagList.length > 1) {
                if (index > -1) {
                    this.tagList.splice(index, 1);
                    this.$router.push(this.tagList[this.tagList.length - 1].path)
                    localStorage.setItem('tagList', JSON.stringify(this.tagList))
                }
            } else if (this.tagList.length == 1 && this.currentPath != '/home/page') {

                this.tagList.splice(index, 1);
                this.$router.push('/home/page')
            }

        },
        closeAll() {
            //关闭全部页
            this.tagList = [{
                path: '/home/page',
                title: '首页'
            }]
            this.$router.push('/home/page')
        },
        delOhter() {
            //关闭其他页
            if (this.tagList.length === 1) {
                return
            }
            this.tagList = this.tagList.filter(item => item.path == this.currentPath)
            console.log(this.tagList)
            this.$router.push(this.tagList[0].path)
        },

        contextmenu(e) {//右键单击事件
            e.preventDefault();
            var x = e.clientX;
            var y = e.clientY;
            // Get the current location
            this.contextMenuData.axis = {
                x,
                y
            };
        },

        show() {
            console.log(123)
        },
        query() {
            let data = {
                pageNum: 1,
                pageSize: 99999
            }
            this.$ajax(config.interfaces.getCompanyList, data).then(res => {
                // console.log(res.data.list,'公司数据')
                if (res.code == 1) {
                    this.companyList = res.data.list
                    // this.companyList.push({
                    //   companyId:0,
                    //   companyName:'天禾云科有限公司'
                    // })
                    // console.log( this.companyList)
                }
            })
        },
        changeval(val) {
            console.log(val)
            this.companyList.forEach(item => {
                if (item.companyId == val) {
                    console.log(item)
                    localStorage.setItem('companyName', item.companyName)
                }
            })
            localStorage.setItem('companyId', val)
            localStorage.setItem('companyId2', val)
            // localStorage.setItem('companyName', obj.companyName)
            // this.$router.push('/home/page')
            let data = {
                companyId: val
            }
            this.$ajax(config.interfaces.getMenusByCompanyId, data).then((res) => {
                console.log(res)
                if (res.code == 1) {
                    // localStorage.setItem('menus', JSON.stringify(res.data))
                    let menus = res.data

                    menus.forEach(item => {
                        if (item.menuId == 68) {
                            item.childMenus = item.childMenus.filter(item => item.menuId != 95 && item.menuId != 96)
                        }
                    })
                    localStorage.setItem('menus', JSON.stringify(menus))
                    this.$router.push('/home/page')
                    // [{"path":"/home/page","title":"首页"}]
                    let data = {"path": "/home/page", "title": "首页"}
                    localStorage.setItem('tagList', JSON.stringify(data))
                    setTimeout(() => {
                        location.reload()
                    }, 0)
                    // this.$router.go(0)
                }
            })
        },
        // tag标签
        handleClose(index, flag) {
            // console.log(index);
            if (this.tagList.length == 1) {
                this.tagList.push({
                    path: "/home/page",
                    title: "首页",
                });

                this.tagList.splice(0, 1);
                this.$router.push("/home/page");
                localStorage.setItem('tagList', JSON.stringify(this.tagList))
                // console.log(this.tagList);
                this.currentPath = '/home/page'
            } else {
                this.tagList.splice(index, 1);
                localStorage.setItem('tagList', JSON.stringify(this.tagList))
                if (flag) {
                    if (index - 1 >= 0) {
                        this.$router.push(this.list[index - 1]);
                    } else {
                        this.$router.push(this.tagList[0])
                    }
                    const isPath = this.tagList.some(item => item.path === this.currentPath)
                    console.log(this.tagList, this.list, this.currentPath, isPath);
                    if (!isPath) this.currentPath = this.tagList[index - 1 >= 0 ? index - 1 : 0].path
                }


            }
        },
        open(item) {
            // console.log(item);
            this.$router.push(item.path);
            this.currentPath = item.path
        },
    },
    watch: {
        $route: {
            handler: function (val, oldVal) {
                // console.log(val.meta, '----------- #路由');
                if (JSON.stringify(this.tagList).indexOf(JSON.stringify(val.meta)) === -1) {
                    if (this.tagList.length < 10) {
                        this.tagList.push(val.meta);
                        localStorage.setItem('tagList', JSON.stringify(this.tagList))
                    } else {
                        this.tagList.splice(0, 1);
                        this.tagList.push(val.meta);
                        localStorage.setItem('tagList', JSON.stringify(this.tagList))
                    }
                    // console.log(val.meta.path)
                    this.list = JSON.parse(localStorage.getItem('tagList'))
                }
                this.currentPath = val.meta.path
                // console.log(this.tagList);
            },
            // 深度观察监听
            deep: true,
            immediate: true,
        },
    },
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

}

.hander {
  height: 36px;
  width: 100%;
  background-color: #009999;
}

.navbarr {
  display: flex;
  align-items: center;
  height: 35px;
  overflow-y: hidden;
  width: 100%;
  background-color: #f2f2f2;
  /*滚动条样式*/

  &::-webkit-scrollbar {
    //width: 4px;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    //box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: rgba(0, 0, 0, 0.2);
    //background-color: red;
  }

  &::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);

  }
}

/deep/ .el-tag {
  height: 30px;
  // width: 50px;
  background-color: #bbe2e2;
  cursor: pointer;
  border-radius: 0;
  margin-left: -1px;
}

.content {
  width: 100%;
  height: 100%;
  //border: 1px solid  red;
  box-sizing: border-box;
}

.content::-webkit-scrollbar {
  width: 0 !important;
}

/deep/ .el-header {
  padding: 0 !important;
}

/deep/ .el-tag .el-icon-close {
  color: black;
}

/deep/ .el-icon-close:before {
  color: black !important;
}

/deep/ .el-card__body,
.el-main {
  padding: 23px;
}

/deep/ .el-card__body,
.el-main {
  padding: 12px;
}

.currentPath {
  background-color: #009999;
  color: #fff;
}

</style>
