import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMenus: false,
    menuList: JSON.parse(localStorage.getItem('menus')) || []
  },
  getters: {
  },
  mutations: {
    // 写入路由传参995959
    setMenuList(state, value) {
      // console.log(value, state, 'value,statevalue,statevalue,state')
      state.menuList = value
    },
    setIsMenus(state, value) {
      state.isMenus = value
    }
  },
  actions: {
  },
  modules: {
  }
})
