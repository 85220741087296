import { Message } from "element-ui";

const offset = 100; // 全局偏移量
const duration = 1500 //弹窗延时时间

let isQueryHandler = false;
export let cacheTableDataGlobal = []; // 全局 列表缓存
/**
 * @description 数字四舍五入
 * @param {*} value 数值
 * @param {*} property 字段
 * @param {*} filter 过滤字段
 * @param {*} step 保留小数位
 * @returns {number}
 */
const getSumProperty = (value, property, filter = [], step = 2) => {
    if (filter.includes(property)) {
        return Number(value || 0).toRound(0)
    }
    return Number(value || 0).toRound(step)
}

export default {
    install(Vue) {
        //勾选标黄
        Vue.prototype.$isYellow = function ({ row }, selectList, key) {
            // console.log('selectList -->', this[selectList])
            const checkIdList = this[selectList].map((item) => item[key]);
            if (checkIdList.includes(row[key])) {
                return {
                    backgroundColor: "#FEA212",
                }
            }
        }
        /**
         * @description 选中当前行勾选标黄
         * @author lxl
         * @param {object} row
         * @param {ref} ref
         * @param {Boolean} clear 是否默认清空之前已选中数据
         * */
        Vue.prototype.$handleCurrentChange = function (row, ref, clear = true) {
            // console.log(row, '-------------------- #选中当前行')
            if (clear) {
                this['$refs'][ref]['clearSelection']()
            }
            this['$refs'][ref]['toggleRowSelection'](row, true)
            row = null
        }
        //校验数值类型
        /**
         * @param {any} row 对象
         * @param {string} type 字段
         * @param {number} step  只有设置此值才会出现浮点数
         * */
        Vue.prototype.$handleNumInput = function handleNumInput(row, type, step) {
            let returnNum = String(row[type])
            returnNum = returnNum.split('')
            const isFloat = returnNum.some(item => item === '.')
            const lastNUm = (arr, indexFloat) => {
                return arr
                    .map(item => item)
                    .filter((item, index) => !isNaN(+item) || isFloat && item === '.' && indexFloat === index)
                    .join('')
            }
            //存在浮点数 且 设置小数点
            if (isFloat && step) {
                // console.log('存在小数点');
                const indexFloat = returnNum.findIndex(item => item === '.')
                const endNum = returnNum.splice(indexFloat + 1)
                if (step) endNum.splice(step, 1)
                const startNum = returnNum.splice(0, indexFloat)
                returnNum = lastNUm([...startNum, '.', ...endNum], indexFloat)
                row[type] = returnNum
            } else {
                returnNum = +lastNUm(returnNum)
                row[type] = returnNum < 1 ? returnNum.length > 3 ? +returnNum : returnNum : +returnNum;
            }
            // console.log(row[type], returnNum, step, 'row[type]row[type]row[type]')
            return row[type]
        }
        /** 数量 金额去除 ","
         * */
        Vue.prototype._deleteComma = function deleteComma(num) {
            return String(num).split(',').join('')
        }
        /** 金额运算每千位增加一个逗号
         * @param price 金额
         * @param fixed 保留小数位
         * */
        Vue.prototype._numFloatDot = function _numFloatDot(price, fixed = 0) {
            return Number(price).toRound(fixed).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
            // const fix = parseInt(fixed);
            // let lastPrice = String(price || 0).split('.');
            // let zeroPrice = lastPrice[0].replace(/\d{1,3}(?=(\d{3})+$)/g, s => s + ',').split('');
            //
            // if (lastPrice.length === 1) {
            //     let zero = '0'
            //     // 已经赋值了一个0了 所以需要减一
            //     for (let i = 0; i < fix - 1; i++) {
            //         zero += '0'
            //     }
            //     // console.log(zero)
            //     lastPrice = `${[...zeroPrice, '.', zero].join('')}`
            // } else { // 补0
            //     function zero(arr, fix) {
            //         // console.log(arr, fix);
            //         let result = String(arr).split('');
            //         // console.log(result, fix - result.length);
            //         let zeroArr = []
            //         for (let i = 0; i < fix - result.length; i++) {
            //             // console.log(i);
            //             zeroArr.push('0')
            //         }
            //         // console.log(zeroArr);
            //         return [...result, ...zeroArr].slice(0, fix).join('')
            //     }
            //
            //     // console.log(lastPrice, zeroPrice, fix, '------------- #lastPrice');
            //     lastPrice = `${zeroPrice.join('')}.${zero(lastPrice[lastPrice.length - 1], fix)}`
            //     // console.log(lastPrice, '------------- #lastPrice222')
            // }
            // if (!fix) {
            //     lastPrice = `${zeroPrice.join('')}`
            // }
            // // console.log(lastPrice, zeroPrice, "----------------- #lastPrice2222222")
            // // console.log(zeroPrice, zeroLength);
            // return lastPrice
        }
        //深度克隆
        Vue.prototype._deepClone = function _deepClone(source) {
            let target
            if (source instanceof Object) {
                target = Array.isArray(source) ? [] : {}
                for (let key in source) {
                    if (source.hasOwnProperty(key)) {
                        if (typeof source[key] !== 'object') {
                            target[key] = source[key]
                        } else {
                            target[key] = _deepClone(source[key])
                        }
                    }
                }
            } else {
                target = source
            }
            return target
        }
        // 长度插值
        Vue.prototype.$differNum = function differNum(startNum, endNum, symbol) {
            // if (!endNum && symbol === "+") return null
            if (!startNum) return;
            if (!endNum) return startNum;
            if (symbol === "+") return +startNum + +endNum;
            if (symbol === "-") return +startNum - +endNum;
        }
        //表单校验
        /**
         * @param {ref} formName ref
         * @param {Function} fn 回调函数
         * */
        Vue.prototype._submitFormValidate = function submitFormValidate(formName, fn) {
            /*
            * @property {formName} 传数组类型时
            * @method {fn} 校验完成后的方法
            * */
            if (formName instanceof Array) {
                let arrFlag = [];
                for (let i = 0; i < formName.length; i++) {
                    let ref = formName[i]
                    this['$refs'][ref].validate((valid) => {
                        arrFlag[i] = !!valid;
                    });
                }
                let flag = arrFlag.every(item => item)
                //当所有判断为true时
                if (flag && fn) {
                    fn(formName)
                }
            }
            /*
            * @param {formName} 传字符串类型时
            * */
            if (typeof formName === 'string') {
                this['$refs'][formName].validate((valid) => {
                    if (valid) {
                        fn && fn(formName)
                        return false;
                    } else {
                        // console.log('error submit!!');
                        return false;
                    }
                });
                return false;
            }
        }
        //重置表单校验
        Vue.prototype._resetFormValidate = function resetFormValidate(formName, fn) {
            //formName Ref / fn 方法
            fn && fn()
            if (formName instanceof Array) {
                formName.forEach(ref => {
                    this['$refs'][ref]['resetFields']()
                })
                return false;
            }
            if (typeof formName === 'string') {
                this['$refs'][formName]['resetFields']()
                return false;
            }
        }
        Vue.prototype.$sortChange = function ({ column, order, prop }, dataList, oldDataList) {
            if (!this[dataList].length) return
            console.log(order, prop)
            let lastAmount = this[dataList][this[dataList].length - 1]
            this[dataList] = this[dataList].filter((item, index) => index !== this[dataList].length - 1)
            // 上
            if (order === 'ascending') {
                this[dataList] = this[dataList].sort((a, b) => {
                    // console.log(!isNaN(a[prop]), new Date(a[prop]).getTime(), '--------- #a[prop]');
                    if (isNaN(a[prop])) {
                        return String(a[prop]).localeCompare(b[prop]);
                    } else {
                        return a[prop] - b[prop]
                    }
                })
            }
            // 下
            else if (order === 'descending') {
                this[dataList] = this[dataList].sort((a, b) => {
                    if (isNaN(a[prop])) {
                        return String(b[prop]).localeCompare(a[prop]);
                    } else {
                        return b[prop] - a[prop]
                    }
                })
            }
            if (!order) {
                // this[dataList] = this[oldDataList]
                console.log(this[dataList], this[oldDataList], 'null')
            }
            this[dataList].push(lastAmount)
        }
        Vue.prototype.$message = function (option) {
            option.offset = offset
            return Message(option)
        }
        Vue.prototype.$message.success = function (msg) {
            return Message.success({
                message: msg,
                offset,
                duration
            })
        }
        Vue.prototype.$message.warning = function (msg) {
            return Message.warning({
                message: msg,
                offset,
                duration
            })
        }
        Vue.prototype.$message.error = function (msg) {
            return Message.error({
                message: msg,
                offset,
                duration
            })
        }
        // 下载文件
        Vue.prototype.$downloadFile = function (url, fileName) {
            let a = document.createElement("a");
            a.href = url;
            const xhr = new XMLHttpRequest();
            xhr.responseType = "blob";
            xhr.onload = function () {
                // 过滤后缀
                const isImage = (name) => {
                    return /\.(gif|jpg|jpeg|png|webp|bmp)$/i.test(name);
                };
                if (!isImage(url)) {
                    a.href = URL.createObjectURL(xhr.response);
                }

                fileName = url.split('//')
                if (fileName.length) {
                    fileName = fileName[fileName.length - 1]
                }
                a.download = fileName || url;
                a.target = "_brank";
                a.click();
                a.remove();
            };
            // console.log(xhr, 'xhr')
            // console.log(url, 'urlurlurl')
            xhr.open("GET", url);
            xhr.send();
        }
        Vue.prototype.$getSummaries = function (param, props) {
            const {
                properties = [], // 字段
                step = 2, // 保留小数位
                intProperties = [], // 整数 不保留小数位
                name = "合计", // 显示字段
                dataProperty = '',
                stepProperty = {},
            } = props;

            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = name;
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (
                    !values.every(value => isNaN(value))
                    && properties.includes(column.property)
                ) {
                    const defaultProperty = column.property;
                    // console.log('defaultProperty -->', defaultProperty)
                    const sumProperty = this[dataProperty] && this[dataProperty][defaultProperty]
                    if (sumProperty || typeof +sumProperty === 'number' && !isNaN(+sumProperty)) {
                        sums[index] = getSumProperty(sumProperty, defaultProperty, intProperties, stepProperty[defaultProperty] || step)
                    } else {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                        sums[index] = getSumProperty(sums[index], defaultProperty, intProperties, stepProperty[defaultProperty] || step)
                    }
                } else {
                    sums[index] = '';
                }
            });
            return sums;
        }
        // 默认合计值
        Vue.prototype.$defaultSum = function (list, props = {}) {
            const {
                properties = [], // 字段
                intProperties = [], // 整数 不保留小数位
                dataProperty = '',
                step = 2,
                stepProperty = {}
            } = props

            const result = {}
            list.forEach(item => {
                properties.forEach(key => {
                    const value = +item[key]
                    if (result[key]) {
                        result[key] += value
                    } else {
                        result[key] = value
                    }
                })
            })
            properties.forEach(key => {
                this.$set(this[dataProperty], key, getSumProperty(result[key], key, intProperties, stepProperty[key] || step))
                // this[dataProperty][key] = getSumProperty(result[key], key, intProperties, stepProperty[key] || step)
            })
        }
        // 查询
        Vue.prototype.$tableQuery = function (props = {}, cb) {
            const {
                tableData = [], // 列表数据
                form = {}, // 查询数据
                /**
                 * 示例 [table内对应字段, form表单 start字段, form表单 end字段]
                 * startEndKeys = [
                 *                     ['length', 'startValue', 'endLength'],
                 *                     ['width', 'startWidth', 'endWidth']
                 *                 ]
                 */
                startEndKeys = [], // 长 初始长 -> 初始宽,
            } = props

            if (!isQueryHandler) {
                cacheTableDataGlobal = JSON.parse(JSON.stringify(tableData)) || []
                // console.log('$tableQuery --> cacheTableDataGlobal', cacheTableDataGlobal)
            }
            isQueryHandler = true
            // 赋值初始值
            const cacheTableData = JSON.parse(JSON.stringify(cacheTableDataGlobal)) || [];

            // 1. 没有查询条件 查询输入没有值 过滤key
            const formKeys = Object.keys(form).filter(key => form[key]);

            // console.log('formKeys', formKeys)
            return cacheTableData.filter(item => {
                return formKeys
                    .every(key => {
                        const isStartEndKeys = startEndKeys.some(items => items.slice(1).includes(key))
                        if (isStartEndKeys) {
                            return startEndKeys.some(items => {
                                const [key, startKey, endKey] = items

                                const startValue = Number(form[startKey])
                                const endValue = Number(form[endKey])
                                const maxValue = (startValue || 0) + (endValue || 0); // 勿动这些 0
                                const minValue = (startValue || 0) - (endValue || 0);
                                if ((startValue && endValue) || startValue || endValue) {
                                    console.log(1111111)
                                    // console.log('maxValue minValue -->', key, maxValue, minValue)
                                    return item[key] >= minValue && item[key] <= maxValue
                                } else {
                                    return false
                                    return String(item[key]).indexOf(String(startValue)) > -1
                                }
                            })
                        } else {
                            // if (key === 'techName') {
                            //     item[key] = item.orderTechList && item.orderTechList.map(item => item.techName).join(',')
                            // }
                            cb && cb(item, key)
                            return String(item[key]).indexOf(form[key]) > -1
                        }
                    });
            })
        }
        /**
         *
         * @param initTableData {*[]} 初始表格数据
         * @returns {*[]}
         */
        Vue.prototype.$tableReset = function (initTableData = []) {
            isQueryHandler = false
            if (initTableData.length || cacheTableDataGlobal.length) {
                return cacheTableDataGlobal.length ? JSON.parse(JSON.stringify(cacheTableDataGlobal)) : initTableData
            } else {
                cacheTableDataGlobal = []
                return []
            }
        }
        /**
         * @param type delete 删除 create 创建 update 更新
         * @param props
         * @returns {*[]}
         */
        Vue.prototype.$setTableCacheStatus = function (type, props = {}) {
            const {
                data, // 列表
                key = '', // id | key 对比字段
                keyValue
            } = props
            if (!['reset'].includes(type)) {
                if (!data) throw new Error('no data')
                if (!key) throw new Error('no key')
            }

            switch (type) {
                case 'reset':
                    cacheTableDataGlobal = [];
                    console.log('cacheTableDataGlobal', cacheTableDataGlobal)
                    break
                case 'delete':
                    const ids = data
                    cacheTableDataGlobal = cacheTableDataGlobal.filter(item => !ids.includes(item[key]))
                    break
                case 'create':
                    const record = Object.assign({}, data)
                    const index = cacheTableDataGlobal.findIndex(item => item[key] === keyValue);
                    if (index >= 0) {
                        cacheTableDataGlobal.splice(index + 1, 0, record);
                    } else {
                        cacheTableDataGlobal.push(record)
                    }
                    console.log('create index -->', index, keyValue, record, cacheTableDataGlobal)
                    break
                case 'update':
                    const list = JSON.parse(JSON.stringify(data))
                    // console.log('update list -->', list)
                    console.log('update start --> cacheTableDataGlobal', cacheTableDataGlobal)

                    // 1. 增量更新
                    cacheTableDataGlobal = cacheTableDataGlobal.map((cur) => {
                        const record = list.find(item => item[key] === cur[key])
                        if (record) {
                            // console.log('if --> 相同直接替换更新', record)
                            Object.assign(cur, record)
                        }
                        return cur
                    })

                    // 2. push 最新数据
                    const keys = cacheTableDataGlobal.map(item => item[key])
                    const _list = list.filter(item => !keys.includes(item[key]))
                    // console.log('else --> 不相同直接替换更新', _list, keys)
                    cacheTableDataGlobal = [...cacheTableDataGlobal, ..._list]

                    console.log('update end --> cacheTableDataGlobal', cacheTableDataGlobal)
                    break
                default:
                    break
            }
        }
    }
}
