// const host = 'http://127.0.0.1:8016'

// const host = 'http://10.77.10.49:8016' //仁达

// const host = 'http://10.77.10.217:8016' //仁达

// const host = 'https://yunkeapi.mfxapp.com' // 梦飞翔

const host = process.env.VUE_APP_API_URL
// const host = 'https://api.thykwh.com';

export default {
    host: host,
    interfaces: {
        // 登录
        Login: host + '/userManage/login',
        // 获取手机验证码
        getCode: host + '/userManage/getCode',
        // 忘记密码
        forgetPwd: host + '/userManage/forgetPwd',
        // 修改密码
        upPwd: host + '/userManage/upPwd',
        // 人员管理
        // 获取权限菜单列表
        getMenuList: host + '/userManage/getMenuList',
        // 获取公司角色管理列表
        getRoleList: host + '/userManage/getRoleList',
        // 添加或者修改角色
        addOrEditRole: host + '/userManage/addOrEditRole',
        // 删除角色
        deleteRole: host + '/userManage/deleteRole',
        // 根据公司id获取管理员权限菜单
        getMenusByCompanyId: host + '/userManage/getMenusByCompanyId',
        // 消息中心
        // 获取告警消息
        getAlarmList: host + '/homeManage/getAlarmList',
        // 处理
        disAlarmMes: host + '/homeManage/disAlarmMes',

        // 获取是否存在未读消息
        getMesRead: host + '/homeManage/getMesRead',
        // 消息阅读
        mesRead: host + '/homeManage/mesRead',

        // 生产线管理
        // 获取生产线列表
        getLineList: host + '/userManage/getLineList',
        // 添加或者修改生产线
        addOrEditLine: host + '/userManage/addOrEditLine',
        // 删除生产线
        deleteLine: host + '/userManage/deleteLine',

        // 人员管理
        // 获取人员列表
        getUserList: host + '/userManage/getUserList',
        // 添加或者修改人员
        addOrEditUser: host + '/userManage/addOrEditUser',
        // 获取生产线
        getLines: host + '/userManage/getLines',
        // 获取工序
        getProcess: host + '/userManage/getProcess',
        // 删除人员
        deleteUser: host + '/userManage/deleteUser',
        // 人员禁用与恢复
        userIsForbid: host + '/userManage/userIsForbid',

        // 获取客户、供应商区域列表
        getRegionList: host + '/userManage/getRegionList',
        // 添加或修改客户、供应商区域
        addOrEditRegion: host + '/userManage/addOrEditRegion',
        // 删除客户、供应商区域
        deleteRegion: host + '/userManage/deleteRegion',

        // 获取客户列表
        getCustomerList: host + '/userManage/getCustomerList',
        // 获取结转
        getJiezhuan: host + '/financeManage/getJiezhuan',
        // 获取结算方式
        getJieSuans: host + '/userManage/getJieSuans',
        // 添加或修改客户
        addOrEditCustomer: host + '/userManage/addOrEditCustomer',
        // 客户启用与禁用
        customerIsForbid: host + '/userManage/customerIsForbid',
        // 删除客户
        deleteCustomer: host + '/userManage/deleteCustomer',
        // 向客户发送消息
        sendMessageForCustomer: host + '/userManage/sendMessageForCustomer',
        // 客户管理导出
        exportCustomerList: host + '/userManage/exportCustomerList',

        // 获取供应商列表
        getSupplierList: host + '/userManage/getSupplierList',
        // 添加供应商
        addOrEditSupplier: host + '/userManage/addOrEditSupplier',
        // 供应商启用、禁用
        supplierIsForbid: host + '/userManage/supplierIsForbid',
        // 删除供应商
        deleteSupplier: host + '/userManage/deleteSupplier',
        // 供应商导出
        exportSupplierList: host + '/userManage/exportSupplierList',
        // 基础管理基础管理基础管理
        // 获取工序
        getProcessList: host + '/basicSetting/getProcessList',
        // 获取计价单位
        getUnits: host + '/basicSetting/getUnits',
        // 添加或修改工序
        addOrEditProcess: host + '/basicSetting/addOrEditProcess',
        // 更改是否只允许补片
        setIsXcxBp: host + '/basicSetting/setIsXcxBp',
        // 删除工序
        deleteProcess: host + '/basicSetting/deleteProcess',
        // 工序上下移动
        yoyoProcess: host + '/basicSetting/yoyoProcess',

        // 获取特殊工艺列表
        getTechList: host + '/basicSetting/getTechList',
        // 添加或修改特殊工艺
        addOrEditTech: host + '/basicSetting/addOrEditTech',
        // 删除特殊工艺
        deleteTech: host + '/basicSetting/deleteTech',

        // 获取付款方式列表
        getPaytypeList: host + '/basicSetting/getPaytypeList',
        // 添加或修改付款方式
        addOrEditPaytype: host + '/basicSetting/addOrEditPaytype',
        // 删除付款方式
        deletePaytype: host + '/basicSetting/deletePaytype',

        // 获取补片原因列表
        getPatchReasonList: host + '/basicSetting/getPatchReasonList',
        // 添加或修改补片原因
        addOrEditPatchReason: host + '/basicSetting/addOrEditPatchReason',
        // 删除补片原因
        deletePatchReason: host + '/basicSetting/deletePatchReason',

        // 获取发票类型列表
        getInvoiceTypeList: host + '/basicSetting/getInvoiceTypeList',
        // 添加或修改发票类型
        addOrEditInvoiceType: host + '/basicSetting/addOrEditInvoiceType',
        // 删除发票类型
        deleteInvoiceType: host + '/basicSetting/deleteInvoiceType',

        // 获取订单审核人
        getApprovalUser: host + '/basicSetting/getApprovalUser',
        // 设置订单审核人
        setOrderApproval: host + '/basicSetting/setOrderApproval',
        // 清空订单审核人
        delApproveUser: host + '/basicSetting/delApproveUser',

        // 获取结算方式列表
        getJiesuanList: host + '/basicSetting/getJiesuanList',
        // 添加或修改结算方式
        addOrEditJiesuan: host + '/basicSetting/addOrEditJiesuan',
        // 删除结算方式
        deleteJiesuan: host + '/basicSetting/deleteJiesuan',

        // 获取告警消息列表
        getAlarmSetList: host + '/basicSetting/getAlarmSetList',
        // 添加或修改告警消息
        addOrEditAlarmSet: host + '/basicSetting/addOrEditAlarmSet',
        // 删除告警消息
        deleteAlarmSet: host + '/basicSetting/deleteAlarmSet',

        // 获取操作日志列表
        getLogList: host + '/basicSetting/getLogList',

        // 获取仓库列表
        getStorehouseList: host + '/basicSetting/getStorehouseList',
        // 添加或修改仓库
        addOrEditStorehouse: host + '/basicSetting/addOrEditStorehouse',
        // 删除仓库
        deleteStorehouse: host + '/basicSetting/deleteStorehouse',
        // 审核
        auditFPurchase: host + '/rawMaterialManage/auditFPurchase',

        //添加或修改辅料入库单
        addOrEditFInstore: host + '/rawMaterialManage/addOrEditFInstore',


        // 分页获取辅料库存列表
        getFStorageList: host + '/rawMaterialManage/getFStorageList',
        // 分页获取出入库明细列表
        getFStorageDetailList: host + '/rawMaterialManage/getFStorageDetailList',


        // 系统设置系统设置系统设置系统设置系统设置
        // 获取角色列表
        getRoleListS: host + '/systemManage/getRoleList',
        // 添加或修改角色
        addOrEditRoleS: host + '/systemManage/addOrEditRole',
        // 删除角色
        deleteRoleS: host + '/systemManage/deleteRole',
        // 获取菜单列表
        getMenuListS: host + '/systemManage/getMenuList',

        // 获取后台用户列表
        getUserListS: host + '/systemManage/getUserList',
        // 添加或修改后台用户
        addOrEditUserS: host + '/systemManage/addOrEditUser',
        // 后台用户启用禁用
        userIsForbidS: host + '/systemManage/userIsForbid',
        // 删除后台用户
        deleteUserS: host + '/systemManage/deleteUser',

        // 获取公司列表
        getCompanyList: host + '/systemManage/getCompanyList',
        // 添加或修改公司列表
        addOrEditCompany: host + '/systemManage/addOrEditCompany',
        // 删除公司列表
        deleteCompany: host + '/systemManage/deleteCompany',
        // 公司启用禁用
        companyIsForbid: host + '/systemManage/companyIsForbid',
        // 到期提醒
        expiredateMes: host + '/systemManage/expiredateMes',

        // 公司模板管理
        getTemplateList: host + '/systemManage/getTemplateList',
        // 添加或者修改公司模板
        addOrEditTemplate: host + '/systemManage/addOrEditTemplate',
        // 删除公司模板
        deleteTemplate: host + '/systemManage/deleteTemplate',

        // 原料管理原料管理原料管理原料管理原料管理原料管理原料管理原料管理
        // 获取原片等级列表
        getLevelList: host + '/rawMaterialManage/getLevelList',
        // 添加或修改原片等级
        addOrEditLevel: host + '/rawMaterialManage/addOrEditLevel',
        // 删除原片等级
        deleteLevel: host + '/rawMaterialManage/deleteLevel',

        // 获取原片颜色列表
        getColorList: host + '/rawMaterialManage/getColorList',
        // 添加或修改原片颜色
        addOrEditColor: host + '/rawMaterialManage/addOrEditColor',
        // 删除原片颜色
        deleteColor: host + '/rawMaterialManage/deleteColor',

        // 获取原片尺寸列表
        getSizeList: host + '/rawMaterialManage/getSizeList',
        // 添加或修改原片尺寸
        addOrEditSize: host + '/rawMaterialManage/addOrEditSize',
        // 删除原片尺寸
        deleteSize: host + '/rawMaterialManage/deleteSize',

        // 获取原片地域列表
        getYRegionList: host + '/rawMaterialManage/getYRegionList',
        // 添加或修改原片地域
        addOrEditYRegion: host + '/rawMaterialManage/addOrEditYRegion',
        // 删除原片地域
        deleteYRegion: host + '/rawMaterialManage/deleteYRegion',

        // 获取原片厚度列表
        getThicknessList: host + '/rawMaterialManage/getThicknessList',
        // 添加或修改原片厚度
        addOrEditThickness: host + '/rawMaterialManage/addOrEditThickness',
        // 删除原片厚度
        deleteThickness: host + '/rawMaterialManage/deleteThickness',

        // 获取辅料类型列表
        getFTypeList: host + '/rawMaterialManage/getFTypeList',
        // 添加或修改辅料类型
        addOrEditFType: host + '/rawMaterialManage/addOrEditFType',
        // 删除辅料类型
        deleteFType: host + '/rawMaterialManage/deleteFType',

        // 获取铝材规格列表
        getSheetSpecialList: host + '/rawMaterialManage/getSheetSpecialList',
        // 添加或修改铝材规格
        addOrEditSheetSpecial: host + '/rawMaterialManage/addOrEditSheetSpecial',
        // 删除铝材规格
        deleteSheetSpecial: host + '/rawMaterialManage/deleteSheetSpecial',

        // 获取设备列表
        getEquipmentList: host + '/rawMaterialManage/getEquipmentList',
        // 添加或修改设备
        addOrEditEquipment: host + '/rawMaterialManage/addOrEditEquipment',
        // 删除设备
        deleteEquipment: host + '/rawMaterialManage/deleteEquipment',

        // 获取周转架采购列表
        getShelfBatchList: host + '/rawMaterialManage/getShelfBatchList',
        // 添加或修改周转架
        addOrEditShelfBatch: host + '/rawMaterialManage/addOrEditShelfBatch',

        // 获取周转架列表
        getShelfList: host + '/rawMaterialManage/getShelfList',
        // 编辑架子状态
        editShelf: host + '/rawMaterialManage/editShelf',
        // 获取原片采购订单列表
        getPurchaseList: host + '/rawMaterialManage/getPurchaseList',
        // 添加或修改原片采购订单
        addOrEditPurchase: host + '/rawMaterialManage/addOrEditPurchase',
        // 删除原片采购订单
        deletePurchase: host + '/rawMaterialManage/deletePurchase',
        // 审核
        auditPurchase: host + '/rawMaterialManage/auditPurchase',

        // 获取原片入库单列表
        getInstoreList: host + '/rawMaterialManage/getInstoreList',
        // 添加或修改入库单
        addOrEditInstore: host + '/rawMaterialManage/addOrEditInstore',
        // 审核入库单
        auditInstore: host + '/rawMaterialManage/auditInstore',

        // 获取退货单列表
        getReturnList: host + '/rawMaterialManage/getReturnList',
        // 添加编辑入库状态
        addOrEditReturn: host + '/rawMaterialManage/addOrEditReturn',
        // 审核原片退货单
        auditReturn: host + '/rawMaterialManage/auditReturn',
        // 删除原片退货单
        deleteReturn: host + '/rawMaterialManage/deleteReturn',

        // 获取原片损耗列表
        getLossList: host + '/rawMaterialManage/getLossList',
        // 添加原片损耗
        addLoss: host + '/rawMaterialManage/addLoss',
        // 删除原片损耗单
        deleteLoss: host + '/rawMaterialManage/deleteLoss',
        // 获取原片库存
        getStorages: host + '/rawMaterialManage/getStorages',

        // 获取原片申领列表
        getApplyList: host + '/rawMaterialManage/getApplyList',
        // 添加原料申领列表
        addApply: host + '/rawMaterialManage/addApply',
        // 删除原片申领单
        deleteApply: host + '/rawMaterialManage/deleteApply',
        // 获取订单列表
        getOrderList: host + '/orderManage/getOrderList',

        // 获取原片退料列表
        getBackList: host + '/rawMaterialManage/getBackList',
        // 添加原片退料
        addBack: host + '/rawMaterialManage/addBack',
        // 删除原片退料
        deleteBack: host + '/rawMaterialManage/deleteBack',


        // 产品类别产品类别产品类别产品类别产品类别
        // 获取产品类别列表
        getItemTypeList: host + '/productManage/getItemTypeList',
        // 添加或修改产品类别
        addOrEditItemType: host + '/productManage/addOrEditItemType',
        // 删除产品类别
        deleteItemType: host + '/productManage/deleteItemType',

        // 获取产品列表
        getItemList: host + '/productManage/getItemList',
        // 添加产品列表
        addOrEditItem: host + '/productManage/addOrEditItem',
        // 删除产品列表
        deleteItem: host + '/productManage/deleteItem',

        // 获取小程序订单列表
        getFOrderList: host + '/productManage/getFOrderList',
        // 订单数据更新 状态变更 、填写快递单号
        fOrderChange: host + '/productManage/fOrderChange',

        // 获取辅料入库单列表
        getFInstoreList: host + '/rawMaterialManage/getFInstoreList',
        // 审核辅料入库单
        auditFInstore: host + '/rawMaterialManage/auditFInstore',


        // 获取辅料退货单列表
        getFReturnList: host + '/rawMaterialManage/getFReturnList',
        // 添加或修改辅料退货单
        addOrEditFReturn: host + '/rawMaterialManage/addOrEditFReturn',
        // 审核辅料退货单
        auditFReturnn: host + '/rawMaterialManage/auditFReturnn',
        // 删除辅料退货单
        deleteFReturn: host + '/rawMaterialManage/deleteFReturn',

        // 分页获取辅料申领列表
        getFApplyList: host + '/rawMaterialManage/getFApplyList',
        // 添加辅料申领
        addFApply: host + '/rawMaterialManage/addFApply',
        // 删除辅料申领单
        deleteFApply: host + '/rawMaterialManage/deleteFApply',

        // 分页获取辅料退料列表
        getFBackList: host + '/rawMaterialManage/getFBackList',
        // 添加辅料退料
        addFBack: host + '/rawMaterialManage/addFBack',
        // 删除辅料退料单
        deleteFBack: host + '/rawMaterialManage/deleteFBack',


        // 获取商品列表
        getGoodsList: host + '/productManage/getGoodsList',
        // 添加或修改商品
        addOrEditGoods: host + '/productManage/addOrEditGoods',
        // 删除商品
        deleteGoods: host + '/productManage/deleteGoods',
        // 获取产品详情
        getItemDetail: host + '/productManage/getItemDetail',

        // 获取辅料采购单列表
        getFPurchaseList: host + '/rawMaterialManage/getFPurchaseList',
        //添加或修改辅料采购订单
        addOrEditFPurchase: host + '/rawMaterialManage/addOrEditFPurchase',
        //删除辅料采购订单
        deleteFPurchase: host + '/rawMaterialManage/deleteFPurchase  ',


        // 财务管理财务管理财务管理财务管理财务管理
        // 分页获取计件工资列表
        getUserSalaryList: host + '/financeManage/getUserSalaryList',
        // 更新用户信息：权重
        updateUser: host + '/financeManage/updateUser',

        // 客户预收款列表
        getKhGetinList: host + '/financeManage/getKhGetinList',
        // 新增编辑预收款
        addOrEditKhGetin: host + '/financeManage/addOrEditKhGetin',
        // 审核预收款
        auditKhGetin: host + '/financeManage/auditKhGetin',
        // 删除预收款
        deleteKhGetin: host + '/financeManage/deleteKhGetin',

        // 客户收款单列表
        getKhReceiptList: host + '/financeManage/getKhReceiptList',
        // 通过公司id获取发货单
        getDeliverList: host + '/financeManage/getDeliverList',
        // 添加编辑收款单
        addOrEditKhReceipt: host + '/financeManage/addOrEditKhReceipt',
        // 审核收款单
        auditKhReceipt: host + '/financeManage/auditKhReceipt',
        // 删除收款单
        deleteKhReceipt: host + '/financeManage/deleteKhReceipt',

        // 获取客户调帐单列表
        getKhAdjustList: host + '/financeManage/getKhAdjustList',
        // 获取订单列表
        getOrderList2: host + '/financeManage/getOrderList',
        // 获取发货单接口
        getDeliverListS: host + '/financeManage/getDeliverList',
        // 新获取发货单接口
        getDeliverListNew: host + '/financeManage/getDeliverListNew',
        // 添加修改客户调帐单
        addOrEditKhAdjust: host + '/financeManage/addOrEditKhAdjust',
        // 审核客户调帐单
        auditKhAdjust: host + '/financeManage/auditKhAdjust',
        // 删除客户调账单
        deleteKhAdjust: host + '/financeManage/deleteKhAdjust',
        // 月末结转
        jiezhuan: host + '/financeManage/jiezhuan',
        // 获取客户对账单列表
        getKhAccountList: host + '/financeManage/getKhAccountList',

        // 供应商
        // 获取供应商预付款列表
        getGysGetoutist: host + '/financeManage/getGysGetoutist',
        // 新增编辑供应商预付款
        addOrEditGysGetout: host + '/financeManage/addOrEditGysGetout',
        // 审核供应商预付款
        auditGysGetout: host + '/financeManage/auditGysGetout',
        // 删除供应商预付款
        deleteGysGetout: host + '/financeManage/deleteGysGetout',

        // 获取供应商付款单列表
        getGysPaymentList: host + '/financeManage/getGysPaymentList',
        // 添加编辑供应商付款单
        addOrEditGysPayment: host + '/financeManage/addOrEditGysPayment',
        // 获取供应商采购列表
        getPurchaseLists: host + '/financeManage/getPurchaseList',
        // 获取入库单列表
        getInstoreLists: host + '/financeManage/getInstoreList',
        // 审核供应商付款单
        auditGysPayment: host + '/financeManage/auditGysPayment',
        // 删除供应商付款单
        deleteGysPayment: host + '/financeManage/deleteGysPayment',


        // 获取供应商调帐单列表
        getGysAdjustList: host + '/financeManage/getGysAdjustList',
        // 添加修改供应商调帐单
        addOrEditGysAdjust: host + '/financeManage/addOrEditGysAdjust',
        // 审核供应商调帐单
        auditGysAdjust: host + '/financeManage/auditGysAdjust',
        // 删除供应商调帐单
        deleteGysAdjust: host + '/financeManage/deleteGysAdjust',

        // 获取供应商对账单列表
        getGysAccountList: host + '/financeManage/getGysAccountList',

        // 获取发票管理列表
        getInvoiceList: host + '/financeManage/getInvoiceList',
        // 添加编辑发票
        addOrEditInvoice: host + '/financeManage/addOrEditInvoice',
        // 删除发票
        deleteInvoice: host + '/financeManage/deleteInvoice',


        // 文件上传
        upload: host + '/upload',

        // 图片二维码
        captcha: host + '/captcha',

        // 获取表字段
        getTableColumnArrVOs: host + '/systemManage/getTableColumnArrVOs',

        // 打印
        getOrderPrintVO: host + '/orderManage/getOrderPrintVO',
        // 获取铝条打印数据
        getLtPrintVO: host + '/orderManage/getLtPrintVO',
        // 获取成品标签数据
        getCpPrintVO: host + '/orderManage/getCpPrintVO',
        // 获取流程单、标签打印数据
        getCardPrintVO: host + '/orderManage/getCardPrintVO',
        // 获取发货单打印数据
        getDeliverPrintVO: host + '/orderManage/getDeliverPrintVO',


        // 分页获取导出模板列表
        getTemplateExportList: host + '/templateManage/getTemplateExportList',
        // 分页获取表字段
        getTableColumnExportList: host + '/templateManage/getTableColumnExportList',
        // 添加、修改导出模板
        addOrEditTemplateExport: host + '/templateManage/addOrEditTemplateExport',
        // 删除导出模板
        deleteTemplate2: host + '/templateManage/deleteTemplate',
        // 导出辅料库存
        exportFStorageList: host + '/rawMaterialManage/exportFStorageList',
        // 导出出入库明细
        exportFStorageDetailList: host + '/rawMaterialManage/exportFStorageDetailList',


        // 报表中心
        getStorageList: host + '/reportCenter/getStorageList',
        // 分页获取出入库明细列表
        getStorageDetailList: host + '/reportCenter/getStorageDetailList',
        // 导出原片库存
        exportStorageList: host + '/reportCenter/exportStorageList',
        // 欠款统计
        getCustomerQk: host + '/reportCenter/getCustomerQk',
        // 发货统计报表
        getSendGoodsStatistics: host + '/orderManage/getSendGoodsStatistics',
        // 补片统计
        getCardPatchStatistics: host + '/orderManage/getCardPatchStatistics',
        // 销售订单统计
        getSaleOrder: host + '/orderManage/getSaleOrder',
        // 销售订单明细统计
        getSaleOrderDetail: host + '/orderManage/getSaleOrderDetail',


        // 生产管理
        getCardList: host + '/orderManage/getCardList',
        // 生产管理 新
        getCardManageList: host + '/orderManage/getCardManageList',
        // 明细
        getCardDetailed: host + '/orderManage/getCardDetailed',
        // 产量报表打印
        getYieldPrint: host + '/orderManage/getYieldPrint',

        // 客户应收款对账单打印
        // 按发货单打印
        AdjustDeliverPrint: host + '/financeManage/AdjustDeliverPrint',
        // 按销售单打印
        AdjustOrderPrint: host + '/financeManage/AdjustOrderPrint',

        // 供应商应收款对账单打印
        GysAccountLsVoPrint: host + '/financeManage/GysAccountLsVoPrint',

        // 发货统计报表-导出
        exportSendGoodsStatistics: host + '/orderManage/exportSendGoodsStatistics',
        // 生产补片报表-导出
        exportCardPatchStatistics: host + '/orderManage/exportCardPatchStatistics',
        // 销售订单统计-导出
        exportSaleOrder: host + '/orderManage/exportSaleOrder',
        // 销售订单明细统计-导出
        exportSaleOrderDetail: host + '/orderManage/exportSaleOrderDetail',
        // 欠款统计报表-导出
        exportCustomerQkList: host + '/reportCenter/exportCustomerQkList',

    },
    //订单列表
    orderList: {
        //主表
        getOrderList: host + '/orderManage/getOrderList',
        //订单 --> 订单明细 --> 列表
        getOrderDetailList: host + '/orderManage/getOrderDetailList',
        //订单 --> 订单明细 --> 发货
        getOrderDetailManageList: host + '/orderManage/getOrderDetailManageList',
        //订单 --> 发货记录 --> 列表
        getRecordDeliverList: host + '/orderManage/getRecordDeliverList',
        //订单 --> 工艺流程 --> 编辑A/B片长宽
        updateAbSpec: host + '/orderManage/updateAbSpec',
        //订单 --> 半成品A/B片 --> 列表
        getCardEchoPage: host + '/orderManage/getCardEchoPage',
        //订单 --> 流程卡进度 --> 列表
        getCardSpeedList: host + '/orderManage/getCardSpeedList',
        //订单 --> 收款记录
        getOrderByKhReceiptList: host + '/orderManage/getOrderByKhReceiptList',
        //新增订单
        addOrder: host + '/orderManage/addOrder',
        //新增订单
        updateOrder: host + '/orderManage/updateOrder',
        //新增订单 --> 获取产品工艺流程
        getItemPocessName: host + '/orderManage/getItemPocessName',
        //订单-删除
        delOrder: host + '/orderManage/delOrder',
        //订单-暂停-取消暂停
        stopOrder: host + '/orderManage/stopOrder',
        //订单-审核
        approveOrder: host + '/orderManage/approveOrder',
        //订单-取消审核
        unApproveOrder: host + '/orderManage/unApproveOrder',
        //订单 --> 分配流程卡 -->数据回显
        getCardEcho: host + '/orderManage/getCardEcho',
        //订单 --> 分配流程卡 --> 生成卡号
        generateCardNo: host + '/orderManage/generateCardNo',
        //订单 --> 分配流程卡
        distCard: host + '/orderManage/distCard',
        //订单产品导入
        importOrderItem: host + '/excel/importOrderItem',

        //订单发货记录-列表
        getDeliverList: host + '/orderManage/getDeliverList',
        //订单发货记录-添加
        sendGoods: host + '/orderManage/sendGoods',
        //订单发货记录-审核
        approveDeliver: host + '/orderManage/approveDeliver',
        //订单发货记录-删除
        delDeliver: host + '/orderManage/delDeliver',
        //订单发货记录-删除
        sendGoodsSeleCard: host + '/orderManage/sendGoodsSeleCard',

        //流程卡列列表
        getCardList: host + '/orderManage/getCardList',
        //流程卡列列表 新
        getCardManageList: host + '/orderManage/getCardManageList',
        //流程卡列列表 --> 明细
        getCardDetailed: host + '/orderManage/getCardDetailed',

        //分卡记录-列表
        getCardRecordList: host + '/orderManage/getCardRecordList',
        //分卡记录-审核 / 取消审核
        approveCardRecord: host + '/orderManage/approveCardRecord',
        //分卡记录-取消分配
        cancelDistCard: host + '/orderManage/cancelDistCard',
        //分卡记录-重新分配
        againDistCard: host + '/orderManage/againDistCard',

        //图片订单-列表
        getOrderPictureList: host + '/orderManage/getOrderPictureList',
        //图片订单-详情
        getOrderPictureDetail: host + '/orderManage/getOrderPictureDetail',

        //退货 --> 列表
        getDeliverReturnList: host + '/orderManage/getDeliverReturnList',
        //新增退货单 --> 选择产品
        seleDeliverItem: host + '/orderManage/seleDeliverItem',
        //退货 --> 删除
        delDeliverReturn: host + '/orderManage/delDeliverReturn',
        //退货 --> 审核
        approveDeliverReturn: host + '/orderManage/approveDeliverReturn',
        //退货 --> 新增
        addDeliverReturn: host + '/orderManage/addDeliverReturn',


        //补片 --> 列表
        getCardPatchList: host + '/orderManage/getCardPatchList',
        //补片 --> 删除
        delCardPatch: host + '/orderManage/delCardPatch',
        //补片 --> 审核
        approveCardPatch: host + '/orderManage/approveCardPatch',
        //补片 --> 添加
        addCardPatch: host + '/orderManage/addCardPatch',
        //补片 --> 选择流程卡 --> AB片
        getCardByPatch: host + '/orderManage/getCardByPatch',

        //成品余料-列表
        getItemRemainList: host + '/orderManage/getItemRemainList',
        //成品余料-详情
        getItemRemainDetail: host + '/orderM anage/getItemRemainDetail',
        //成品余料-成品余料-新增、编辑
        operationItemRemain: host + '/orderManage/operationItemRemain',
        //成品余料-删除
        delItemRemain: host + '/orderManage/delItemRemain',

        // 流程卡导出
        exportCardDetail: host + '/orderManage/exportCardDetail',
        // 合并导出并优化流程卡导出
        exportCardDetail2: host + '/orderManage/exportCardDetail2',
        // 订单导出
        exportOrderDetail: host + '/orderManage/exportOrderDetail',
        //轮播图列表
        getAdvList: host + '/orderManage/getAdvList',
        //轮播图删除
        delAdv: host + '/orderManage/delAdv',
        //轮播图-新增、编辑
        operationAdv: host + '/orderManage/operationAdv',

        //更改录单状态
        updateIsRecord: host + '/orderManage/updateIsRecord',
        // 获取单片面积不足的值
        getInsuArea: host + '/orderManage/getInsuArea',

        // 校验订单：（客户名称、类别，产品名称，厚度）
        ckManageOrder: host + '/orderManage/ckManageOrder',
        // 发货列表导出
        exportDeliverList: host + '/orderManage/exportDeliverList',

        //校验成品余料：（类别，长、宽、数量）
        ckManageItemRemain: host + '/orderManage/ckManageItemRemain',
        // 减去成品余料
        subItemRemain: host + '/orderManage/subItemRemain',

        editOrderItemPrice: host + '/orderManage/editOrderItemPrice',
        // 获取原价格
        getItemPriceLast: host + '/orderManage/getItemPriceLast',
        // 获取分卡记录详情
        getCardRecordDetail: host + '/orderManage/getCardRecordDetail',
        // 订单主表 合计
        getOrderTotal: host + '/orderManage/getOrderTotal',
        // 订单主表 合计
        getCpPrintSale: host + '/orderManage/getCpPrintSale',

    }
}
