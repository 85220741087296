import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/login/login.vue';
import Layout from '../views/layout/layout.vue';
import NotFound from '../views/notFound/index.vue';
import store from '@/store'


Vue.use(VueRouter)

// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch((err) => err);
};


//静态路由
const routes = [
    {path: "/", redirect: "/login"},
    {path: "/login", component: Login},
    {path: "/layout", component: Layout, redirect: "/home/page",},
    // 首页
    {
        path: '/home', meta: {path: '/home', title: '首页'}, component: Layout, redirect: "/home/page", children: [
            {path: "page", meta: {path: "/home/page", title: "首页"}, component: () => import("../views/home/page")},
            {
                path: "news",
                meta: {path: "/home/news", title: "消息中心"},
                component: () => import("../views/home/news")
            },
        ]
    },
    // 打印
    {
        path: '/hiprint',
        meta: {path: '/hiprint', title: '打印设置'},
        component: Layout,
        redirect: "/hiprint/hiprint",
        children: [
            {
                path: "hiprint",
                meta: {path: "/hiprint/hiprint", title: "打印设置"},
                component: () => import("../views/hiprint")
            }
        ]
    },
    {
        path: '*',
        meta: {path: '*', title: 'Not Found'},
        alias: "/404",
        component: NotFound,
    },
];

const menus = JSON.parse(localStorage.getItem('menus')) || [];
const childrenFn = (item = []) => {
    let childrenArr = [];
    item.length && item.forEach(val => {
        if (val.childMenus) {
            childrenArr.push(...val.childMenus)
        } else {
            childrenArr.push(val)
        }
    })
    // console.log(childrenArr, '------------------- #childrenArr')
    return childrenArr.map(val => {
        return {
            path: `${val.menuUrl}`,
            meta: {
                path: `${val.menuUrl}`,
                title: `${val.menuName}`,
            },
            name: `${val.menuName}`,
            component: () => import(`../views${val.menuUrl}`)
        }
    })
};

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
const getMenusRouters = (item) => {
    router.addRoute({
        path: `/${item.menuUrl}`,
        meta: {
            path: `/${item.menuUrl}`,
            title: `${item.menuName}`,
        },
        name: `${item.menuName}`,
        component: Layout,
        redirect: `${item.childMenus[0].menuUrl}`,
        children: childrenFn(item.childMenus)
    })
}
//动态路由
store.state.menuList.forEach(item => getMenusRouters(item));

// 获取本地存储的数据是否相同
const getMenuLength = (arr1 = [], arr2 = []) => {
    let flag = true;
    // 1.长度不同直接返回false
    if (arr1.length !== arr2.length) return false;

    arr1.forEach((item) => {
        arr2.forEach((val) => {
            if (item.menuId !== val.menuId) {
                console.log(11111);
                flag = false;
            }
            if (item.childMenus || val.childMenus) {
                console.log(22222);
                flag = getMenuLength(item.childMenus || [], val.childMenus || []);
            }
        });
    });

    // console.log(1111111);
    return flag;
};
router.beforeEach((to, from, next) => {
    const menus = JSON.parse(localStorage.getItem("menus")) || [];
    const token = localStorage.token
    // if (token && !getMenuLength(menus, store.state.menuList)) {
    //     store.commit('setMenuList', menus)
    //     router.go(0);
    // }
    console.log(store.state, 'storestore')
    if (store.state.isMenus) {
        store.state.menuList.forEach(item => getMenusRouters(item))
        store.commit('setIsMenus', false)
        next({
            ...to,
            replace: true
        })
    } else {
        next()
    }
    //获取token
})


export default router;
