<template>
    <div class="top">
        <div class="topBOx" style="margin-left: 16px">
            <div class="img">
                <img src="../assets/images/logo3.png" alt="">
            </div>
            <div class="wenzi">天禾云科管理系统</div>
        </div>

        <div class="menu">
            <el-menu
                    :default-active="activeIndex"
                    class="el-menu-demo forgot"
                    mode="horizontal"
                    @select="handleSelect"
                    background-color="#009999"
                    text-color="#fff"
                    active-text-color='#fff'
                    router

            >
                <el-submenu :index="`${item.menuId}`" v-for="(item,index) in menus" :key="item.menuId">
                    <template slot="title">{{ item.menuName }}</template>
                    <el-menu-item v-for="children in item.childMenus" :key="children.menuId" :index="children.menuUrl"
                                  v-if="!children.childMenus">
                        {{ children.menuName }}
                    </el-menu-item>
                    <el-submenu class="aaac" v-for="(children,i) in item.childMenus" :key="children.menuId"
                                :index="`${children.menuId}`"
                                v-if="children.childMenus">
                        <template slot="title">{{ children.menuName }}</template>
                        <el-menu-item v-for="val in children.childMenus" :key="val.menuId" :index="val.menuUrl">{{
                            val.menuName
                            }}
                        </el-menu-item>
                    </el-submenu>
                </el-submenu>
            </el-menu>
        </div>
        <div class="rightbox">

            <div class="rightbox2">
                <div class="xiaoxi">
                    <el-badge :is-dot="flag">
                        <i class="el-icon-bell" @click="toXiaoxi"></i>
                    </el-badge>

                </div>
                <div class="checkUsers">
                    <el-dropdown trigger="click" @command="handleCommand">
      <span class="el-dropdown-link">{{ name }}<i class="el-icon-caret-bottom"></i>
      </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="a">个人中心</el-dropdown-item>
                            <el-dropdown-item command="b">修改密码</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>

            <div class="goOut">
                <i class="el-icon-switch-button" @click="goLogin()"></i>
                <div class="goOut-in">
                    <el-select v-model="value" placeholder="" @change="changeval" v-if="value2==='天禾云科有限公司'">
                        <el-option v-for="(item,index) in companyList" :key="item.companyId" :label="item.companyName"
                                   :value="item.companyId"></el-option>
                    </el-select>
                    <p v-else>{{ value }}</p>
                </div>
            </div>
        </div>


        <el-row>
            <el-dialog v-el-drag-dialog
                       title="个人信息"
                       :visible.sync="dialogVisible"
                       width="400px"
            >
                <el-form>
                    <el-form-item label="公司名称" label-width="120px">
                        {{ companyName }}
                    </el-form-item>
                    <el-form-item label="姓名" label-width="120px">
                        {{ userName }}
                    </el-form-item>
                    <el-form-item label="账号" label-width="120px">
                        {{ phone }}
                    </el-form-item>
                    <el-form-item label="账号到期日期" label-width="120px" v-if="companyId == 0">
                        {{ deadline }}
                    </el-form-item>
                </el-form>
            </el-dialog>
        </el-row>

        <el-row>
            <el-dialog v-el-drag-dialog
                       title="修改密码"
                       :visible.sync="dialogVisible2"
                       width="400px"
            >
                <el-form :model="formObj" :rules="rules" ref="ruleForm">
                    <el-form-item label="原密码" label-width="120px" prop="oldPwd">
                        <el-input type="password" autocomplete="off" style="width: 186px" v-model="formObj.oldPwd"
                                  placeholder="请输入原密码"></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" label-width="120px" prop="pwd">
                        <el-input type="password" autocomplete="off" style="width: 186px" v-model="formObj.pwd"
                                  placeholder="请输入新密码"></el-input>
                    </el-form-item>
                    <el-form-item label="确定密码" label-width="120px" prop="pwdTwo">
                        <el-input type="password" autocomplete="off" style="width: 186px" v-model="formObj.pwdTwo"
                                  placeholder="请再次输入密码"></el-input>
                    </el-form-item>

                </el-form>
                <span slot="footer" class="dialog-footer">
           <el-button plain
                      @click="clearForm('ruleForm')"
                      size="small"
           >取消</el-button
           >
          <el-button plain
                     @click="save('ruleForm')"
                     size="small"
          >确定</el-button
          >
        </span>
            </el-dialog>
        </el-row>

    </div>
</template>

<script>
import config from "@/config";


export default {
    data() {
        const checkPwdTwo = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.formObj.pwd) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            rules: {
                pwd: [
                    {required: true, message: '请输入新密码', trigger: 'blur'}
                ],
                oldPwd: [
                    {required: true, message: '请输入原密码', trigger: 'blur'},
                ],
                pwdTwo: [

                    {required: true, message: '请再次输入密码', trigger: 'blur'},
                    {validator: checkPwdTwo, trigger: 'blur'}
                ]
            },
            activeIndex: "1",
            menus: JSON.parse(localStorage.getItem('menus')),
            name: localStorage.getItem('userName'),
            dialogVisible: false,
            value: localStorage.getItem('companyName'),
            value2: localStorage.getItem('companyName33'),
            companyList: [],
            companyName: localStorage.getItem('companyName'),
            companyId: localStorage.getItem('companyId'),
            userName: localStorage.getItem('userName'),
            phone: localStorage.getItem('phone'),
            deadline: localStorage.getItem('deadline'),
            flag: true,
            dialogVisible2: false,
            formObj: {
                pwd: '',
                oldPwd: '',
                pwdTwo: '',
            }
        };
    },
    mounted() {
        this.query()
        this.getMesRead()
    },
    methods: {

        save(formName) {
            this.$refs[formName].validate((valid) => {
                // console.log(valid)
                if (valid) {
                    this.$ajax(config.interfaces.upPwd, this.formObj).then(res => {
                        if (res.code == 1) {
                            this.$message.success(res.message)
                            this.clearForm()
                            this.dialogVisible2 = false
                        }
                    })
                }
            })

        },
        clearForm() {
            this.formObj = {
                pwd: '',
                oldPwd: '',
                pwdTwo: '',
            }
            this.$refs['ruleForm'].resetFields();
            this.dialogVisible2 = false
        },
        getMesRead() {
            this.$ajax(config.interfaces.getMesRead).then(res => {
                console.log(res)
                if (res.code == 1) {
                    if (res.data == 0) {
                        this.flag = false
                    }
                }
            })
        },
        query() {
            let data = {
                pageNum: 1,
                pageSize: 99999
            }
            this.$ajax(config.interfaces.getCompanyList, data).then(res => {
                // console.log(res.data.list,'公司数据')
                if (res.code == 1) {
                    this.companyList = res.data.list
                    // this.companyList.push({
                    //   companyId:0,
                    //   companyName:'天禾云科有限公司'
                    // })
                    // console.log( this.companyList)
                }
            })
        },
        changeval(val) {
            console.log(val)
            this.companyList.forEach(item => {
                if (item.companyId == val) {
                    console.log(item)
                    this.value = item.companyName
                    localStorage.setItem('companyName', item.companyName)
                }
            })
            localStorage.setItem('companyId', val)
            localStorage.setItem('companyId2', val)
            localStorage.setItem('isChange', 2)
            let data = {
                companyId: val
            }
            this.$ajax(config.interfaces.getMenusByCompanyId, data)
                .then((res) => {
                    console.log(res)
                    if (res.code == 1) {
                        // localStorage.setItem('menus', JSON.stringify(res.data))
                        let menus = res.data
                        menus.forEach(item => {
                            if (item.menuId == 68) {
                                item.childMenus = item.childMenus.filter(item => item.menuId != 95 && item.menuId != 96)
                            }
                        })
                        localStorage.setItem('menus', JSON.stringify(menus))
                        this.$router.push('/home/page')
                        // [{"path":"/home/page","title":"首页"}]
                        let data = {"path": "/home/page", "title": "首页"}
                        localStorage.setItem('tagList', JSON.stringify(data))
                        setTimeout(() => {
                            this.$router.go(0)
                        }, 300)
                        // this.$router.go(0)
                    }
                })
        },
        handleSelect(key, keyPath) {
            // console.log(key, keyPath);
        },
        handleCommand(command) {
            if (command == 'a') {
                console.log('个人中心')
                this.dialogVisible = true
            } else {
                this.dialogVisible2 = true
            }
        },
        toXiaoxi() {
            this.$ajax(config.interfaces.mesRead).then(res => {
                console.log(res)
                if (res.code == 1) {
                    this.flag = false
                }
            })
            this.$router.push('/home/news')
        },
        // 退出登录
        goLogin() {
            this.$router.replace('/login')
            localStorage.setItem('isLogin', 0)
            localStorage.removeItem('token')
            localStorage.removeItem('value2')
            localStorage.removeItem('companyId')
            localStorage.removeItem('companyName33')
            localStorage.removeItem('companyId2')

            // console.log(12);
        }
    },

};
</script>

<style lang="less" scoped>
.el-menu .el-menu-item {
  &:hover {
    background-color: #009999 !important;
  }
}

.rightbox {
  height: 100%;
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-right: -10px;
}

/deep/ .el-select > .el-input > .el-input__inner {
  background-color: #f2f2f2 !important;
  border: none !important;
  color: #009999;
  height: 22px;

}

/deep/ .el-select .el-input .el-select__caret {
  margin-top: -10px !important;
}

/deep/ .el-input__icon {
  width: auto !important;
  height: auto !important;
}

/deep/ .el-select {
  margin-top: -8px !important;
}

.topBOx {
  width: 250px;
  display: flex;
  align-items: center;
}

.rightbox2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.rightbox3 {
  position: absolute;
  top: 68px;
  right: 82px;
}

.sleete {
  width: 160px;
}

/deep/ .el-header {
  padding: 0 !important;
}

/deep/ .el-menu-demo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/ .el-input__inner {
  color: #009999;
}

/deep/ .el-menu--horizontal > .el-submenu {
  clear: left;
  width: 106px;
}

.menu {

  width: 1300px;
  height: 100%;
  overflow-y: hidden;
  scrollbar-width: none;
}

/*滚动条样式*/
.menu::-webkit-scrollbar {
  //width: 4px;
  height: 6px;
}


//
//.menu::-webkit-scrollbar-thumb {
//  border-radius: 4px;
//  //box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
//  background: rgba(0, 0, 0, 0.2);
//  //background-color: red;
//}
//
//.menu::-webkit-scrollbar-track {
//  //box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
//  border-radius: 0;
//  background: rgba(0, 0, 0, 0.1);
//
//}

.top {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .img {
    width: 36px;
    height: 36px;
    // border: 1px solid red;
    //border-radius: 50%;
    //overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .wenzi {
    font-size: 20px;
    font-weight: 500;
    color: white;
    margin-left: 12px;
    white-space: nowrap;
  }

  .xiaoxi {
    //margin-left: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;

    .el-icon-bell {
      font-size: 20px;
      color: white;
    }
  }

  .checkUsers {
    margin-left: 25px;
    //width: 120px;
    height: 80%;
    //border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  /deep/ .el-menu-item {
    font-size: 14px !important;
  }

  .goOut {
    cursor: pointer;
    position: relative;

    .el-icon-switch-button {
      color: #E72427;
      font-size: 20px;
      font-weight: 700;
    }

    .rightbox3 {

    }

    .goOut-in {
      position: absolute;
      top: 45px;
      right: 0;
      color: #009999;
      white-space: nowrap;

      /deep/ .el-input__inner {
        width: 250px;
        text-align: right !important;
      }
    }
  }
}

/deep/ .el-menu.el-menu--horizontal {
  border: none;
}


/deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
  border: none;
}

/deep/ .el-input--suffix .el-input__inner {
  //padding: 0;
}

/deep/ .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border: none;
}

/deep/ .el-icon-arrow-down:before {
  content: "\e6df";
  font-size: 12px;
  color: white;
  font-weight: 800;
}

/deep/ .el-icon-arrow-right::before {
  content: "\e6df";
  font-size: 12px;
  color: white;
  font-weight: 800;
}

/deep/ .el-submenu {
  //width: 80px;
}

///deep/ .el-submenu {
//  width: 70px;
//}
/deep/ .el-form-item__content > .el-input > .el-input__inner {
  height: 30px !important;
  border-radius: 0 !important;
  font-size: 12px !important;
  border-color: #c9c9c9 !important;
  color: black !important;
}

/deep/ .el-dropdown-link {
  font-size: 12px;
  color: white;
  white-space: nowrap;
}

/deep/ .el-icon-caret-bottom {
  margin-left: 3px;
}

/deep/ .el-submenu__title {
  font-size: 12px;
}

.el-menu-item {
  font-size: 12px;
}

/deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
  font-size: 14px !important;
}

</style>
