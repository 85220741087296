<template>
  <span v-html="floatToComma(showNum, fixed)">
    <slot></slot>
  </span>
</template>

<script>

export default {
  name: "ShowNum",
  props: ["showNum", "fixed"],
  data() {
    return {};
  },
  methods: {
    floatToComma(price, fixed = 0) {
      return Number(price)
          .toRound(fixed)
          .toString()
          .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
    },
    // floatToComma(price, fixed) {
    //   const fix = parseInt(fixed);
    //   let lastPrice = String(price || 0).split('.');
    //   let zeroPrice = lastPrice[0].replace(/\d{1,3}(?=(\d{3})+$)/g, s => s + ',').split('');
    //
    //   // let zeroPrice = lastPrice[0].split('');
    //   // let zeroLength = Math.floor(zeroPrice.length / 3);
    //   // zeroPrice = zeroPrice.reverse()
    //   // // console.log(zeroLength, '----------- #zeroLength');
    //   // // console.log(price, fix, "----------------- #lastPrice111111")
    //   // if (zeroPrice.length % 3 === 0) zeroLength = zeroLength - 1
    //   // for (let i = 0; i < zeroLength; i++) {
    //   //   zeroPrice.splice(3 * (i + 1) + i, 0, ',')
    //   // }
    //   //
    //   // // console.log(zeroPrice);
    //   // zeroPrice = zeroPrice.reverse();
    //
    //   if (lastPrice.length === 1) {
    //     let zero = '0'
    //     // 已经赋值了一个0了 所以需要减一
    //     for (let i = 0; i < fix - 1; i++) {
    //       zero += '0'
    //     }
    //     // console.log(zero)
    //     lastPrice = `${[...zeroPrice, '.', zero].join('')}`
    //   } else { // 补0
    //     function zero(arr, fix) {
    //       // console.log(arr, fix);
    //       let result = String(arr).split('');
    //       // console.log(result, fix - result.length);
    //       let zeroArr = []
    //       for (let i = 0; i < fix - result.length; i++) {
    //         // console.log(i);
    //         zeroArr.push('0')
    //       }
    //       // console.log(zeroArr);
    //       return [...result, ...zeroArr].slice(0, fix).join('')
    //     }
    //
    //     // console.log(lastPrice, zeroPrice, fix, '------------- #lastPrice');
    //     lastPrice = `${zeroPrice.join('')}.${zero(lastPrice[lastPrice.length - 1], fix)}`
    //     // console.log(lastPrice, '------------- #lastPrice222')
    //   }
    //   if (!fix) {
    //     lastPrice = `${zeroPrice.join('')}`
    //   }
    //   // console.log(lastPrice, zeroPrice, "----------------- #lastPrice2222222")
    //   // console.log(zeroPrice, zeroLength);
    //   return lastPrice
    // }
  },
};
</script>

<style scoped lang="less"></style>
