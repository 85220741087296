<template>
    <div class="box">
        <div class="box-a">
            <div class="top">
                武汉天禾云科ERP管理系统
            </div>
            <div class="center">
                <div class="lines">

                </div>
                <div class="center-box">
                    <p>TIAN</p>
                    <p>HE</p>
                    <p>YU</p>
                    <p>KE</p>
                </div>
                <div class="lines">

                </div>
            </div>
            <div class="bottom" :style="{height:show?'380px':'500px',padding:show? 0: '18px'}">
                <div class="bottom-b">
                    <el-form :model="form" :rules="rules" ref="ruleForm">
                        <el-form-item prop="phone">
                            <el-input v-model="form.phone" class="inputOne" maxlength="11"
                                      prefix-icon="el-icon-user-solid"
                                      placeholder="请输入手机号码">
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="smsCode" v-if="!show">
                            <el-input v-model="form.smsCode" class="inputOne"
                                      placeholder="请输入验证码"
                                      style="position: relative;">
                                <i slot="prefix" class="iconfont icon-dunpai- el-input__icon"></i>
                            </el-input>
                            <div class="button-y">
                                <p v-if="showCode" @click="send">发送</p>
                                <p v-else>{{ count }}</p>
                            </div>
                        </el-form-item>
                        <el-form-item prop="pwd" v-if="show">
                            <el-input v-model="form.pwd" class="inputOne" type="password" prefix-icon="el-icon-lock"
                                      placeholder="请输入密码">
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="pwdd" v-if="!show">
                            <el-input v-model="form.pwdd" class="inputOne" type="password" prefix-icon="el-icon-lock"
                                      placeholder="请输入密码">
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="pwdTwo" v-if="!show">
                            <el-input v-model="form.pwdTwo" class="inputOne" type="password" prefix-icon="el-icon-lock"
                                      placeholder="请再次输入密码">
                            </el-input>
                        </el-form-item>
                        <el-form-item class="marginOne" prop="captcha" v-if="show">
                            <el-input v-model="form.captcha" @keydown.enter.native="login('ruleForm')" class="inputOne"
                                      style="width: 78%" placeholder="请输入验证码">
                                <i slot="prefix" class="iconfont icon-dunpai- el-input__icon"></i>
                            </el-input>
                            <div class="verification" @click="changeCode">
                                <!--                <img :src="img">-->
                                <identify :identifyCode="identifyCode"></identify>
                            </div>
                        </el-form-item>
                        <div @click="forgetPwd()" class="forgot"
                             style="text-align: right;margin-top: 18px;cursor:pointer;font-size: 13px"
                             v-if="show">
                            忘记密码
                        </div>
                        <el-button class="login-btn" @click="login('ruleForm')" v-if="show"
                                   :loading="isLoginBtnLoading">立即登录
                        </el-button>
                        <el-form-item v-if="!show">
                            <el-button class="buttonWidth qu" @click="quXiao">取消</el-button>
                            <el-button class="buttonWidth en" @click="enterForPwd('ruleForm')">确定</el-button>

                        </el-form-item>
                    </el-form>
                </div>
            </div>

        </div>
        <div class="beian">
            <!--            <a href="https://beian.miit.gov.cn/" target="_blank">您的备案号</a>-->
            Copyright &copy; 2023 武汉天禾云科玻璃科技有限公司 版权所有 最终解释权 &emsp;
            <a href="https://beian.miit.gov.cn/" target="_blank">
                <img src="../../assets/images/china.png" alt="" class="img">
                鄂ICP备2023006985号-1
            </a>
        </div>
    </div>
</template>

<script>
import {isMobile, isPWDS} from "@/utils/tool";
import config from "@/config";
import identify from "@/components/identifyCode";
//获取用户登录设备


export default {
    components: {
        identify
    },
    data() {

        const checkPhone = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请输入手机号'))
            } else if (!isMobile(value)) {
                callback(new Error('请输入正确手机号'))
            } else {
                callback()
            }
        }
        const checkPwds = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'))
            } else if (!isPWDS(value)) {
                callback(new Error('密码长度为8-18,必须由数字字母组合!'))
            } else {
                callback()
            }
        }
        const checkPwdTwo = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.form.pwdd) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            identifyCode: 'm6a8',// 这个就是随机生成的验证码
            // 验证码的随机取值范围
            identifyCodes: '123456789abcdefghjkmnpqrstuvwxyz',
            form: {
                phone: '',
                pwd: '',
                pwdd: '',
                pwdTwo: '',
                smsId: '',
                smsCode: '',
                smsCodee: '',
            },
            img: '',
            show: true,
            showCode: true,
            count: 0,
            timer: '',
            rules: {
                captcha: [
                    {required: true, message: '请输入验证码', trigger: 'blur'}
                ],
                phone: [
                    {required: true, message: '请输入手机号', trigger: 'blur'},
                    {validator: checkPhone, trigger: 'blur'}
                ],
                pwd: [
                    {required: true, message: '请输入密码', trigger: 'blur'}
                ],
                pwdd: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                ],
                smsCode: [
                    {required: true, message: '请输入验证码', trigger: 'blur'}
                ],
                pwdTwo: [
                    {validator: checkPwdTwo, trigger: 'blur'}
                ]
            },
            isLoginBtnLoading: false,
        }
    },
    mounted() {
        // this.getCaptcha()
        // 刷新页面就生成随机验证码
        this.identifyCode = ''
        this.makeCode(this.identifyCodes, 4)
        let token =  localStorage.getItem('token')
        if(token){
            this.$router.push('/home/page');
        }
    },
    // components: {
    //   identify
    // },
    methods: {
        changeCode() {
            this.identifyCode = ''
            this.makeCode(this.identifyCodes, 4)
        },
        randomNum(min, max) {
            max = max + 1
            return Math.floor(Math.random() * (max - min) + min)
        },
        makeCode(data, len) {
            for (let i = 0; i < len; i++) {
                this.identifyCode += data[this.randomNum(0, data.length - 1)]
            }
        },

        // 获取图形验证码
        getCaptcha() {
            this.$picCode(config.interfaces.captcha, '', 'GET').then((res) => {
                console.log(res)
                this.img = res
                // sessionStorage.setItem('aa','123')

            })
            // this.$ajax(config.interfaces.captcha, '', 'GET').then((res)=>{
            //   console.log(res)
            // })
        },
        // 忘记密码
        forgetPwd() {
            // console.log(1)
            this.show = !this.show
            // location.reload()
            this.clearForm()
            this.$refs['ruleForm'].resetFields();
            console.log(this.show)
        },
        // 发送验证码
        send() {
            let data = {
                phone: this.form.phone,
                type: 3 //忘记密码
            }
            if (this.form.phone) {
                console.log(data)
                this.$ajax(config.interfaces.getCode, data).then((res) => {
                    console.log(res)
                    if (res.code == 1) {
                        this.form.smsId = res.data.smsId
                        this.showCode = false
                        this.count = 60
                        this.timer = setInterval(() => {
                            if (this.count > 0) {
                                this.count--
                            } else {
                                console.log('进来了')
                                clearInterval(this.timer)
                                this.showCode = true
                            }
                        }, 1000)
                    }
                })
            } else {
                this.$message.error('请输入手机号码')
            }

        },
        enterForPwd(formName) {
            this.$refs[formName].validate((valid) => {
                    // console.log(valid)
                    if (valid) {
                        // console.log(1)
                        let data = {
                            phone: this.form.phone,
                            pwd: this.form.pwdd,
                            pwdTwo: this.form.pwdTwo,
                            smsId: this.form.smsId,
                            smsCode: this.form.smsCode,
                        }
                        this.$ajax(config.interfaces.forgetPwd, data).then((res) => {
                            console.log(res)
                            if (res.code == 1) {
                                this.$message.success(res.message)
                                this.$refs[formName].resetFields();
                                this.show = true
                            }
                        })
                    }
                }
            )

        },
        quXiao() {
            this.show = true
            this.clearForm()
            this.$refs['ruleForm'].resetFields();
        },
        clearForm() {
            this.form = {
                phone: '',
                pwd: '',
                pwdd: '',
                pwdTwo: '',
                smsId: '',
                smsCode: '',
            }
        },
        login(formName) {
            // this.$router.push('/home/page')
            this.$refs[formName].validate((valid) => {
                // console.log(valid)
                if (valid) {
                    console.log(this.identifyCode, this.form.captcha)
                    if (this.identifyCode != this.form.captcha) {
                        console.log('不一致')
                        this.$message.error('验证码错误')
                        this.changeCode();// 改变验证码
                        return
                    } else {
                        console.log('通过')
                    }
                    console.log('成功')
                    let dataForm = {
                        phone: this.form.phone,
                        pwd: this.form.pwd,
                        // captcha:this.form.captcha
                    }
                    console.log(dataForm, '入参')
                    this.isLoginBtnLoading = true;
                    this.$ajax(config.interfaces.Login, dataForm)
                        .then((res) => {
                            console.log(res)
                            if (res.code == 1) {
                                this.$message.success('登录成功')
                                if(localStorage.getItem('uid')){
                                    localStorage.setItem('loginUid',localStorage.getItem('uid'))
                                }
                                localStorage.setItem('token', res.data.token)
                                localStorage.setItem('companyId', res.data.companyId)
                                localStorage.setItem('companyId2', res.data.companyId)
                                if (res.data.companyId == 0) {
                                    localStorage.setItem('companyName33', '天禾云科有限公司')
                                    localStorage.setItem('companyName', '天禾云科有限公司')
                                } else {
                                    localStorage.setItem('companyName', res.data.companyName)
                                }
                                localStorage.setItem('uid', res.data.uid)

                                localStorage.setItem('userName', res.data.userName)

                                localStorage.setItem('is', 0)

                                localStorage.setItem('isXcx', res.data.isXcx)

                                localStorage.setItem('deadline', res.data.deadline)

                                localStorage.setItem('phone', res.data.phone)
                                localStorage.setItem('isChange', 1)

                                localStorage.setItem('quanxuan', JSON.stringify(res.data.menus))
                                let menus = res.data.menus

                                menus.forEach(item => {
                                    if (item.menuId == 68) {
                                        item.childMenus = item.childMenus.filter(item => item.menuId != 95 && item.menuId != 96)

                                    }
                                })
                                console.log(menus)
                                localStorage.setItem('menus', JSON.stringify(menus))
                                this.$store.commit('setMenuList', menus)
                                this.$store.commit('setIsMenus', true)

                                this.$router.push('/home/page');
                            } else {
                                this.$message.error(res.message)

                            }
                        })
                        .finally(_ => {
                            this.isLoginBtnLoading = false;
                            this.changeCode();
                        })
                }
            })
        }

    }
}
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
  //border: 1px solid red;
  box-sizing: border-box;
  background: url('@/assets/images/33.png') no-repeat 0 -80px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  //border: 1px solid red;
  background-color: #22324c;
}

.box-a {
  width: 6000px;
  height: 500px;
  transform: scale(0.86);
  //border: 1px solid red;
  //margin-top: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -120px;
}

.top {
  width: 850px;
  //height: 100px;
  //border: 1px solid red;
  font-size: 60px;
  color: white;
  //font-weight: bold;
  font-family: '宋体';
  //display: flex;
  font-weight: 700;
  letter-spacing: 8px;
  display: flex;
  justify-content: center;
}

.center {
  width: 700px;
  //height: 80px;
  //border: 1px solid red;
  white-space: nowrap;
  text-align: center;
  font-size: 24px;
  //font-weight: 400;
  font-style: normal;
  //text-decoration: none;
  font-family: Gadugi;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  font-weight: normal;

  .center-box {
    width: 250px;
    display: flex;
    justify-content: space-around;
    font-size: 24px;
    font-width: 400;
    //border: 1px solid red;
  }
}

.lines {
  width: 190px;
  border-top: 1px solid #fff;
}

.bottom {
  margin-top: 35px;
  width: 590px;
  //height: 410px;
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-b {
  width: 90%;
  height: 80%;
  //border: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/ .el-form-item__content {
  margin-top: 11px !important;
}

.verification {
  //width: 85px;
  height: 40px;
  margin-left: 9px;
  //border: 1px solid black;
  margin-top: -11px;

  img {
    width: 100%;
    height: 100%;
  }
}

.login-btn {
  width: 100%;
  height: 48px;
  margin-top: 15px;
  /* background: rgba(110, 101, 255, 1); */
  background: #039A9A;
  font-size: 16px;
  color: #fff;
  font-family: Segoe UI;
  /* border: 1px solid rgba(110, 101, 255, 1); */
  //border: 1px solid #4D7BCD;
}

.login-btn:hover,
.login-btn:focus {
  color: #fff;
  border-color: #039A9A;
  background-color: #039A9A;
}

.button-y {
  width: 63px;
  padding: 0 15px;
  height: 25px;
  //border: 1px solid red;
  border-radius: 15px;
  background: #039A9A;
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: white;
  /* line-height: 88px; */
  position: absolute;
  top: 13px;
  right: 20px;
  cursor: pointer;

  p {
    width: 100%;
    height: 100%;
  }
}

.buttonWidth {
  width: 235px;
}

.qu:hover,
.qu:focus {
  color: #000;
  border-color: #CDCDCD;
  background-color: #fff;
}

.en {
  background-color: #039A9A;
  color: white;
}

.en:hover,
.en:focus {
  color: #fff;
  border-color: #039A9A;
  background-color: #039A9A;
}

/deep/ .el-form-item__content {
  display: flex;
  align-items: center;
  line-height: 47px !important;
}

/deep/ .marginOne {
  margin: 0 !important;
}

/deep/ .el-form-item {
  margin-bottom: 15px;
}

/deep/ .inputOne {
  //border-radius: 0 !important;
  //background-color: rgb(242, 242, 242) !important;
  width: 480px;
  height: 56px;
  font-size: 17px;
}

/deep/ .el-input__inner {
  height: 52px;
}

/deep/ .el-input__prefix {
  display: flex !important;
  align-items: center !important;
}

/deep/ .el-input--prefix .el-input__inner {
  padding-left: 60px;
}

/deep/ .el-input__inner {
  background: #F2F2F2 !important;

}

/deep/ .el-input__icon {
  color: #009999 !important;
  font-size: 28px;
  height: 48%;
  width: 40px;
  border-right: 2px solid #DEDEDE !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -5px;
}

.forgot {
  font-family: Segoe UI;
  font-weight: 600;
}

/deep/ .el-form-item__error {
    font-size: 14px;
    margin-top: 3px;
}

.beian {
    color: #fff;
    position: fixed;
    bottom: 0;
    height: 50px;
    line-height: 50px;

    a {
        .img {
            vertical-align: middle;
        }

        text-decoration: none;
        color: #fff;
    }
}
</style>
