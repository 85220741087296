import axios from 'axios'
import config from '@/config'

//设置axios默认Content-Type form-data
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers['client'] = '1'

axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
    // console.log(config);
    // 获取token
    let TOKEN = localStorage.token;
    // 设置token
    // let TOKEN = '123456'
    if (TOKEN) {
        config.headers['token'] = TOKEN;
    }
    config.headers['client'] = 1;
    let companyId = JSON.parse(localStorage.getItem('companyId'));
    if (companyId) {
        config.headers['companyId'] = companyId;
    }
    // 返回配置项
    return config;
}, function (error) {
    //请求错误时做些事
    console.log("请求失败");
    return Promise.reject(error);
});
export default {
    install(Vue) {
        Vue.prototype.$ajax = function (port, params, method, headers) {
            let that = this
            //获取缓存数据
            // let token = localStorage.getItem('token') || ''
            params = params || {};
            method = method || 'post'
            return new Promise(function (resolve, reject) {
                axios({
                    method: method,
                    url: port,
                    data: JSON.stringify(params)
                })
                    .then(res => {
                        if (res.data.code == 1) {
                            resolve(res.data)
                            // } else if (res.data.code == -1) {
                        } else if (res.data.code == -1) {
                            reject(res.data)
                            console.log(res);
                            that.$message.error(res.data.message)
                            if (res.data.message == '会话过期' || res.data.message == "无效的token!" || res.data.message == "缺少token参数!") {
                                that.$message.error('会话已过期，请重新登录')
                                // that.$message({ type: error, message: '提示内容' }); //way2
                                localStorage.removeItem('token');
                                localStorage.removeItem('uid');
                                setTimeout(function () {
                                    that.$router.push({path: '/login'})
                                }, 1000)
                            }
                        } else if (res.data.code === -2) {
                            reject(res.data)
                            console.log(res, 'ssss');
                            let requestTimer = sessionStorage.getItem('requestTimer') || null
                            if (requestTimer) return clearTimeout(requestTimer)
                            sessionStorage.setItem('requestTimer', requestTimer)
                            requestTimer = setTimeout(() => {
                                that.$message.error('会话已过期，请重新登录')
                                setTimeout(function () {
                                    sessionStorage.removeItem('requestTimer')
                                    localStorage.removeItem('token');
                                    localStorage.removeItem('uid');
                                    that.$router.push('/login')
                                }, 1000)
                            })
                        } else {
                            reject(res.data)
                        }
                    })
                    .catch(error => {
                        reject(error)
                        console.log(error, 'reject')
                    })
            } /* executor */);
        }

        // 文件上传
        Vue.prototype.$upload = function (file) {
            let formData = new FormData()
            formData.append('filename', file)
            return new Promise(function (resolve, reject) {
                axios.post(config.interfaces.multifileupload, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    transformRequest: []
                }).then(res => {
                    resolve(res.data);
                }).catch(err => {
                    console.log(err);
                });
            })
        }

        Vue.prototype.$downloadCsv = function () {
            return new Promise(function (resolve, reject) {
                axios.post('/api/download/csv', null, {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'text/csv'
                    }
                }).then(response => {
                    let url = window.URL.createObjectURL(new Blob([response.data]))
                    let link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    link.setAttribute('download', 'data.csv')
                    document.body.appendChild(link)
                    link.click()
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        }


        // 单文件上传
        // 单文件上传
        Vue.prototype.$upload2 = function (file) {
            let formData = new FormData()
            formData.append('file', file)
            return new Promise(function (resolve, reject) {
                axios.post(config.interfaces.upload, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    transformRequest: []
                }).then(res => {
                    resolve(res.data);
                }).catch(err => {
                    console.log(err);
                });
            })
        }
        // 导入文件
        Vue.prototype.$ImportUserExcel = function (url, file) {
            let formData = new FormData()
            formData.append('file', file)
            return new Promise(function (resolve, reject) {
                axios.post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    transformRequest: []
                }).then(res => {
                    resolve(res.data);
                }).catch(err => {
                    reject(err);
                });
            })
        }
        //下载请求
        Vue.prototype.$download = function (port, params, fileName) {
            let self = this;
            let token = localStorage.getItem('token')
            token = token || "";
            params = params || {};
            if (token == "") {
                console.error("token为空")
            } else if (!port) {
                console.error("未定义接口名")
            } else {
                // params.btoken = token;
                return new Promise(function (resolve, reject) {
                    return axios.post(port, params, {responseType: 'blob'}).then(res => {
                        console.log(res, 'down')
                        if (res.data.status == 2) {
                            self.$message({
                                title: '提示',
                                message: res.data.message,
                                type: 'info'
                            });
                            if (res.data.message.indexOf('会话过期') > -1) {
                                setTimeout(function () {
                                    self.$router.push({path: '/login'})
                                }, 3000)
                            }
                        } else if (res.data.status == 0) {
                            resolve(res.data)
                            self.$message({
                                title: '错误',
                                message: res.data.message,
                                type: 'error'
                            });
                        } else {
                            // console.log(res.data, 'res.data')
                            var blob = new Blob([res.data])
                            console.log(blob, 'blob')
                            var downloadElement = document.createElement('a');
                            var href = window.URL.createObjectURL(blob); //创建下载的链接
                            downloadElement.href = href;
                            downloadElement.download = fileName + new Date().getTime() + '.xls'; //下载后文件名
                            document.body.appendChild(downloadElement);
                            downloadElement.click(); //点击下载
                            document.body.removeChild(downloadElement); //下载完成移除元素
                            window.URL.revokeObjectURL(href); //释放掉blob对象
                        }
                    }).catch(res => {
                        reject(res)
                    })
                } /* executor */);
            }
        }

        // 图片流
        Vue.prototype.$picCode = function (port, params, method) {
            let that = this
            //获取缓存数据
            // console.log(that.$route.meta.id, 'this.$route.meta.id')
            let token = localStorage.getItem('token') || ''
            // let menuid = that.$route.meta.id
            params = params || {};
            params.token = token;
            method = method || 'post'
            return new Promise(function (resolve, reject) {
                axios({
                    method: method,
                    url: port,
                    // headers: {
                    //     menuid: menuid,
                    // },
                    data: JSON.stringify(params),
                    responseType: 'blob',
                }).then(res => {
                    console.log(res, 'down')
                    if (res.data.status == 2) {
                        self.$message({
                            title: '提示',
                            message: res.data.message,
                            type: 'info'
                        });
                        if (res.data.message.indexOf('会话过期') > -1) {
                            setTimeout(function () {
                                self.$router.push({path: '/login'})
                            }, 3000)
                        }
                    } else if (res.data.status == 0) {
                        resolve(res.data)
                        self.$message({
                            title: '错误',
                            message: res.data.message,
                            type: 'error'
                        });
                    } else {
                        // console.log(res.data, 'res.data')
                        var blob = new Blob([res.data])
                        console.log(blob, 'blob')
                        // var downloadElement = document.createElement('a');
                        var href = window.URL.createObjectURL(blob); //创建下载的链接
                        // downloadElement.href = href;
                        resolve(href)
                        // downloadElement.download = fileName + new Date().getTime() + '.xls'; //下载后文件名
                        // document.body.appendChild(downloadElement);
                        // downloadElement.click(); //点击下载
                        // document.body.removeChild(downloadElement); //下载完成移除元素
                        // window.URL.revokeObjectURL(href); //释放掉blob对象
                    }
                }).catch(res => {
                    reject(res)
                })
            } /* executor */);
        }
    }
}
