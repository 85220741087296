<template>
    <div class="contents">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },


}
</script>

<style lang="less">
.contents {
  height: 100%;
  width: 100%;

  box-sizing: border-box;
}


</style>