import Vue from 'vue'
import App from './App.vue'
import router from './router'
import $isYellow from '@/utils/common'
import $handleCurrentChange from '@/utils/common'
import $handleNumInput from '@/utils/common'
import _deepClone from '@/utils/common'
import $submitFormValidate from '@/utils/common'
import $downloadFile from '@/utils/common'
import $getSummaries from '@/utils/common'
import $defaultSum from '@/utils/common'
import $tableQuery from '@/utils/common'
import $tableReset from '@/utils/common'
import $setTableCacheStatus from '@/utils/common'

import ShowNum from "@/components/showNum";
import ElePagination from "@/components/ElePagination";

// 引入element-ui的样式
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import elDragDialog from './utils/dialog' //引入移动事件
// 修改elementui的默认样式
import './assets/css/ele.css'

// 去除css默认样式
import './assets/css/index.css'

// 接口
// 处理验证码的图片显示
import $ajax from '@/api/index';
import $picCode from '@/api/index';
// 打印
import {hiPrintPlugin} from 'vue-plugin-hiprint'


// vue本地存储
import Storage from 'vue-ls'
// $bus事件线
import Bus from '../src/utils/bus'
// 引入字体图标库
import './assets/font/iconfont.css'
import VueContextMenu from 'vue-contextmenu'
import store from '@/store'
import {toRound} from '@/utils/tool'


Vue.component('ShowNum', ShowNum)
Vue.component('ElePagination', ElePagination)

Number.prototype.toRound = toRound

ElementUI.Dialog.props.closeOnClickModal.default = false;
Vue.use(ElementUI)
    .use($isYellow)
    .use($handleCurrentChange)
    .use($handleNumInput)
    .use(_deepClone)
    .use($submitFormValidate)
    .use($downloadFile)
    .use($getSummaries)
    .use($defaultSum)
    .use($tableQuery)
    .use($tableReset)
    .use($setTableCacheStatus)

Vue.directive('el-drag-dialog', elDragDialog);//添加标签事件绑定 可以放大移动弹窗
Vue.use($ajax)
    .use($picCode)
    .use(store)

// 关闭自动连接
// hiPrintPlugin.disAutoConnect();
Vue.use(hiPrintPlugin);

let options = {
    namespace: 'hiPrint-',
    name: 'ls',
    storage: 'local',
};
Vue.use(Storage, options);


Vue.prototype.$bus = Bus

Vue.use(VueContextMenu)


Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
